import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import { useMe } from '../../hooks/useMe.hook';
import { Navbar } from '../../components/Navbar/Navbar';
import './VendorBrandLayout.scss';
import { VendorLockContext } from '@bottomless/common/src/context/vendor-lock.context';
import { VENDOR_IDS } from '../../constants/vendor';

export const VendorBrandLayout = ({ children, noNavigationBar, vendor, noHeadingLink }) => {
  const me = useMe();
  const noLink = useMemo(() => noHeadingLink || me?.vendor_id?._id === VENDOR_IDS.AG1 || me?.rechargeSubscriptionId, [
    me,
    noHeadingLink,
  ]);

  return (
    <div className={classNames('vendor-brand-layout', { 'with-navbar': !!me && !noNavigationBar })}>
      {!!me && !noNavigationBar && !vendor && <Navbar small sticky noLink={noLink} />}
      {vendor && (
        <VendorLockContext.Provider value={vendor}>
          <Navbar small sticky noLink={noLink} />
        </VendorLockContext.Provider>
      )}
      <Container className="d-flex justify-content-center">
        <div className="vendor-brand-container w-100">{children}</div>
      </Container>
    </div>
  );
};

VendorBrandLayout.propTypes = {
  children: PropTypes.node,
  vendor: PropTypes.object,
  noNavigationBar: PropTypes.bool,
  noHeadingLink: PropTypes.bool,
};
