import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from 'react-feather';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import { useSafeCallback } from '@bottomless/common/src/hooks/use-safe-callback';
import { addAlertAction, addToastAction, changeScaleAction } from '@bottomless/common/store';
import { Account } from '../../pages/ChangeAccount/components';
import { newScaleCheckoutAction } from '../../store';

const AccountsModalComponent = ({
  me,
  isOpen,
  toggle,
  matchedAccounts,
  changeScale,
  productData,
  makeBottomlessProduct,
  newScaleCheckout,
  addProductToCustomList,
  isCustomList,
  addToast,
  addAlert,
}) => {
  const [isProcessing, setProcessing] = useState(false);
  const [accountChanging, setAccountChanging] = useState(null);
  const history = useHistory();
  const { search } = useLocation();

  const [onAccountChosen] = useSafeCallback(
    async chosenAccount => {
      try {
        setAccountChanging(chosenAccount._id);

        if (me._id !== chosenAccount._id) {
          await changeScale({ account: chosenAccount._id });
        }

        // eslint-disable-next-line no-unused-vars
        const { vendorId, product, ...data } = productData;

        if (isCustomList) {
          await addProductToCustomList({ _id: product.product }, { variant: product.variant, ...data });
          const back = new URLSearchParams(search).get('back');

          if (back) {
            addToast('Product has been successfully added');
          }
          history.push(back || '/shop');
        } else {
          await makeBottomlessProduct(product.product, { variant: product.variant, ...data });
          addAlert('Product has been successfully saved', 'success');
          history.push('/profile');
        }
      } catch (e) {
        setAccountChanging(null);
        throw e;
      }
    },
    [setAccountChanging, changeScale, history]
  );

  const createNewSubscription = useCallback(async () => {
    try {
      setProcessing(true);
      const { payload } = await newScaleCheckout({
        product: productData.product.product,
        variant: productData.product.variant,
      });
      history.push(`/get_started/${payload._id}`);
    } catch (e) {
      setProcessing(false);
      throw e;
    }
  }, [history, newScaleCheckout, productData]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" className="change-accounts-modal">
      <ModalHeader toggle={toggle} />
      <ModalBody className="pb-4">
        <p className="heading">
          {isCustomList && 'Choose the scale to add this product'}
          {!isCustomList && 'Assign this product to your existing scale'}
        </p>
        <Row className="align-items-center flex-column">
          <Row className="justify-content-center w-100">
            {matchedAccounts.map(account => (
              <Col xs="6" md="4" className="mb-1" key={account._id}>
                <Account
                  disabled={account.vendor_id && account.vendor_id !== productData.vendorId}
                  account={account}
                  choosingAccount={accountChanging === account._id}
                  onAccountChosen={onAccountChosen}
                />
              </Col>
            ))}
          </Row>
          {!isCustomList && (
            <Col xs="12" md="4" className="mb-5 d-flex flex-column">
              <div className="d-flex align-items-center my-4 text-secondary small">
                <span className="border-bottom w-100" style={{ height: '1px' }} />
                <span className="text-black-50 mx-2">OR</span>
                <span className="border-bottom w-100" style={{ height: '1px' }} />
              </div>
              <Button disabled={isProcessing} color="primary" size="sm" onClick={createNewSubscription}>
                {isProcessing && (
                  <span className="d-flex align-items-center justify-content-center">
                    Processing... <Loader size="13" className="spin ml-2" />
                  </span>
                )}
                {!isProcessing && 'Create New Subscription'}
              </Button>
            </Col>
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
};

AccountsModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  matchedAccounts: PropTypes.array.isRequired,
  changeScale: PropTypes.func.isRequired,
  productData: PropTypes.object.isRequired,
  makeBottomlessProduct: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  newScaleCheckout: PropTypes.func.isRequired,
  addProductToCustomList: PropTypes.func,
  isCustomList: PropTypes.bool.isRequired,
  me: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  addToast: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
};

export const AccountsModal = connect(
  ({ user }) => ({ me: user.me }),
  dispatch => ({
    changeScale: data => dispatch(changeScaleAction(data)),
    newScaleCheckout: data => dispatch(newScaleCheckoutAction(data)),
    addToast: message => dispatch(addToastAction(message)),
    addAlert: (message, type) => dispatch(addAlertAction(message, type)),
  })
)(AccountsModalComponent);
