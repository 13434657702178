import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { Toasts } from '@bottomless/common/components';
import { Footer } from '../../components/Footer/Footer';
import { Navbar } from '../../components/Navbar/Navbar';
import { CobrandedFooter } from '../../components/Footer/Cobranded-footer';
import './Simple.scss';

export const Simple = ({
  children,
  stretch,
  small,
  withFooter,
  withSignUpButton,
  sticky,
  withNavbar,
  className,
  footer: Footer,
  banner,
  categorySlug,
  withCheckoutButton,
  back,
  withBackButton,
  isShopifyCheckout,
  shopifyVendorLogo,
  heading,
  checkoutVendor,
  useVendorHeaderLink,
  forceNoVendor,
  hideVendor,
  noLink,
}) => {
  const backProps = useMemo(() => {
    if (!back) {
      return undefined;
    }

    if (back === '/pets') {
      return { onClick: () => (window.location.href = back) };
    }

    if (typeof back === 'string') {
      return { to: back };
    }

    return {
      onClick: e => {
        e.preventDefault();
        back();
      },
    };
  }, [back]);

  return (
    <>
      <div className="skip-to-main">
        <a href="#main-content" className="text-dark">
          Skip to main content
        </a>
      </div>
      <div
        className={classNames('page-simple', {
          'page-simple--stretch': stretch,
          [className]: className,
        })}
      >
        {withNavbar && (
          <Navbar
            small={small}
            withSignUpButton={withSignUpButton}
            withCheckoutButton={withCheckoutButton}
            sticky={sticky}
            categorySlug={categorySlug}
            isShopifyCheckout={isShopifyCheckout}
            shopifyVendorLogo={shopifyVendorLogo}
            heading={heading}
            checkoutVendor={checkoutVendor}
            useVendorHeaderLink={useVendorHeaderLink}
            forceNoVendor={forceNoVendor}
            hideVendor={hideVendor}
            noLink={noLink}
          />
        )}

        <main className="page-content" id="main-content">
          {withBackButton && back && (
            <div className="justify-content-center container">
              <Link
                {...backProps}
                className="d-flex align-items-center navbar-brand flex-grow-1 mr-0 justify-content-center  heading-back"
              >
                <ArrowLeft size="22" color="rgb(35, 35, 35)" strokeWidth="3" role="img" aria-label="Back" />
              </Link>
            </div>
          )}
          {banner}
          {children}
        </main>
        {withFooter ? !isShopifyCheckout ? <Footer /> : <CobrandedFooter /> : ''}
        <Toasts />
      </div>
    </>
  );
};

Simple.defaultProps = {
  stretch: false,
  small: true,
  withNavbar: true,
  withFooter: false,
  withSignUpButton: true,
  footer: Footer,
  isShopifyCheckout: false,
};

Simple.propTypes = {
  children: PropTypes.node.isRequired,
  stretch: PropTypes.bool,
  small: PropTypes.bool,
  withNavbar: PropTypes.bool,
  withFooter: PropTypes.bool,
  withSignUpButton: PropTypes.bool,
  sticky: PropTypes.bool,
  className: PropTypes.string,
  footer: PropTypes.elementType,
  banner: PropTypes.node,
  categorySlug: PropTypes.string,
  withCheckoutButton: PropTypes.bool,
  back: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  withBackButton: PropTypes.bool,
  isShopifyCheckout: PropTypes.bool,
  shopifyVendorLogo: PropTypes.string,
  heading: PropTypes.node,
  checkoutVendor: PropTypes.object,
  useVendorHeaderLink: PropTypes.bool,
  forceNoVendor: PropTypes.bool,
  hideVendor: PropTypes.bool,
  noLink: PropTypes.bool,
};
